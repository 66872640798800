<template>
  <base-page-layout>
    <div>
      <v-layout class="error-page pa-0" row wrap fill-height align-center>
        <v-flex xs12 sm6 class="text-block">
          <p class="error-title"><v-icon class="mb-2 mr-2" color="error" x-large>mdi-alert-circle</v-icon> {{ title }}</p>
          <p class="error-message">{{ firstMessage }}</p>
          <p class="error-message">
            {{ secondMessage }} <span class="message-focus">{{ focusMessage }}</span>
          </p>
          <p v-if="underMessage" class="error-under-message">{{ underMessage }}</p>
          <slot name="buttons"></slot>
        </v-flex>
        <v-flex xs12 sm6 class="error-image">
          <img :src="imagePath" class="responsive-image" />
        </v-flex>
      </v-layout>
    </div>
  </base-page-layout>
</template>

<script>
import BasePageLayout from '@/components/base/BasePageLayout.vue'
export default {
  components: {
    BasePageLayout
  },
  props: {
    icon: {
      type: String,
      required: false
    },
    colorType: {
      type: String,
      required: false,
      validator: value => ['success', 'error'].indexOf(value) !== -1
    },
    title: {
      type: String,
      required: false
    },
    firstMessage: {
      type: String,
      required: false
    },
    secondMessage: {
      type: String,
      required: false
    },
    focusMessage: {
      type: String,
      required: false
    },
    underMessage: {
      type: String,
      required: false
    },
    imageName: {
      type: String,
      required: true
    }
  },
  computed: {
    imagePath() {
      return require(`@/assets/${this.imageName}`)
    }
  }
}
</script>

<style lang="scss">
.error-page {
  height: 85vh;
  @media screen and (min-width: 600px) {
    img {
      max-width: 30vw;
    }
  }
  button {
    margin-top: 15px;
    border-radius: 5px;
    margin-left: 0;
  }
  .text-block {
    padding-left: 10%;
    padding-right: 5%;
  }
  .error-type {
    font-size: 18px;
    margin: 0;
  }
  .error-title {
    font-size: 36px;
    opacity: 0.8;
    font-weight: bold;
  }
  .error-message {
    font-size: 16px;
    color: #656565;
    margin-bottom: 3px;
  }
  .message-focus {
    color: #737b84;
  }
  .error-under-message {
    font-size: 24px;
    color: #2488e3;
  }
  .error-image {
    text-align: center;
  }
}
</style>
