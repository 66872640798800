<template>
  <v-layout v-if="routes" align-left>
    <v-breadcrumbs :items="routes" divider=">" class="pa-0">
      <template slot="item" slot-scope="props">
        <li>
          <a class="v-breadcrumbs__item" @click="breadCrumb(props.item)">{{ props.item.name }}</a>
        </li>
      </template>
    </v-breadcrumbs>
  </v-layout>
</template>

<script>
export default {
  computed: {
    routes() {
      return this.$router.currentRoute.meta.breadcrumbs
    },
    routesName() {
      return this.routes.map(route => {
        return {
          text: route.name
        }
      })
    }
  },
  methods: {
    breadCrumb(route) {
      this.$router
        .push({
          path: route.pathName,
          params: this.$route.params
        })
        .catch(() => {})
    }
  }
}
</script>
<style lang="scss">
.v-breadcrumbs {
  user-select: none;
  padding: 0;
  > li {
    > .v-breadcrumbs__item {
      font-size: 12px;
      padding: 0 8px;
      height: 30px;
      line-height: 30px;
    }
  }
}
</style>
