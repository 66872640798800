<template>
  <div>
    <v-container v-if="showHeader" class="base-page-layout pb-4" fluid>
      <v-container class="pa-0 mb-3 page-actions" fluid fill-height>
        <v-layout align-center>
          <v-btn v-if="showBackButton" class="ma-0"> <v-icon small>arrow_back_ios</v-icon>Voltar </v-btn>
          <breadcrumbs v-if="$vuetify.breakpoint.smAndUp && !hideBreadcrumbs" class="breadcrumbs" />
        </v-layout>
      </v-container>
      <v-layout>
        <slot name="title">
          <p class="my-2 ml-1 page-title">{{ title }}</p>
        </slot>
        <v-spacer />
      </v-layout>
      <slot></slot>
    </v-container>
    <slot v-else></slot>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/base/Breadcrumbs.vue'

export default {
  components: {
    Breadcrumbs
  },
  props: {
    title: String,
    hideBreadcrumbs: Boolean,
    showBackButton: Boolean,
    showHeader: {
      type: Boolean,
      default: () => true
    }
  }
}
</script>
<style lang="scss">
.base-page-layout {
  .alerta {
    font-weight: bold;
    position: absolute;
    top: 0;
    margin: 0;
    left: 0;
    width: 100%;
    padding: 8px;
    border-radius: 0px;
    a {
      color: white;
    }
  }
}
@media print {
  .page-actions {
    display: none !important;
  }
}
@media print {
  .page-actions {
    display: none !important;
  }
}

.page-title {
  font-size: 26px;
  font-weight: 400;
  line-height: '120%';
  letter-spacing: 0;
  color: '#4b525a';
  font-family: 'Lato', sans-serif;
  &::first-letter {
    text-transform: capitalize;
  }
}

.page-title {
  font-size: 22px;
  font-weight: 400;
  line-height: '120%';
  letter-spacing: 0;
  color: '#4b525a';
  font-family: 'Lato', sans-serif;
  &::first-letter {
    text-transform: capitalize;
  }
}

.page-subtitle {
  font-weight: normal;
  font-size: 22px;
  color: #4b525a;
}
</style>
